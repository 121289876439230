import React from "react"
import { graphql } from "gatsby"
import DownloadRedirector from "../../components/Downloader"

function DocumentsPage(props) {
  if (!props.data || !props.data.allPrismicDocuments.edges.length > 0) return null 

  const type = props.data.allPrismicDocuments.edges[0].node.type
  const url = props.data.allPrismicDocuments.edges[0].node.data.document_file.url
  const title = props.data.allPrismicDocuments.edges[0].node.data.document_title.text

  return (
    <DownloadRedirector
      location={props.location}
      type={type}
      url={url}
      title={title}
    />
  )
}

export const query = graphql`
  query DocumentsPageQuery($uid: String) {
    allPrismicDocuments(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          uid
          type
          data {
            document_title {
              text
            }
            document_file {
              url
            }
          }
        }
      }
    }
  }
`

export default DocumentsPage
